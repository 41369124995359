/**=====================
    custom style
==========================**/
.rounded-25 {
  border-radius: 25px!important;
}
/**=====================
    color
==========================**/
.text-blue {color:#1B8B56}
.text-gray {color:#A2A2A2}
.text-red {color:#E94057}
.text-amber {color:#FF6B01}


/**=====================
    font
==========================**/
.font-weight-bold {
  font-weight: bold;
}

/**=====================
    font
==========================**/
.card.dark {
  background-color: #EFEEFB;
  box-shadow: 0px 0px 0px 3px #EAE9F9;
}

/**=====================
    card
==========================**/
.card .mainComponent {
  font-size: 1.5rem;
}

.card .subComponent {
  font-size: 1.0rem;
}

@media only screen and (max-width: 1660px) { 
  .card .mainComponent {
    font-size: 1rem;
  }
  
  .card .subComponent {
    font-size: 0.8rem;
  }
}